export default {
  beforeMount() {
    const escapeHandler = (e) => {
      if (e.key === 'Escape') {
        this.$emit('cancel')
      }
    }
    document.addEventListener('keydown', escapeHandler)
    this.$once('hook:destroyed', () => {
      document.removeEventListener('keydown', escapeHandler)
    })
  }
}
