<template>
  <div id="modalLogin" class="modal" @click="close">
    <div class="modal__card modal__card--login">
      <div
        @click.stop=""
        class="modal__dialog"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div class="modal__header">
          <div class="modal__back"></div>
          <div class="modal__close">
            <img
              src="../../../assets/img/ico/ico-close.svg"
              alt="Cerrar"
              @click="$emit('cancel')"
            />
          </div>
        </div>
        <div class="modal__content">
          <div class="login-box login-box--modal">
            <h3 class="login-box__title mar-b-fs" id="modal-headline">
              Accede a tu lista
            </h3>
            <p class="login-box__description">
              Para poder añadir productos, primero debes acceder a tu lista o crear tu
              lista
            </p>

            <Form
              class="login-box__inputs"
              :operation="operation"
              :valid="valid"
              :error="error"
            >
              <template>
                <div class="bb-form-item">
                  <label for="email" class="bb-form-label">Tu email:</label>
                  <FormInput
                    class="bb-form"
                    v-model="email"
                    type="email"
                    :invalid="emailEmpty"
                    placeholder="Tu email"
                    :required="true"
                  />
                </div>
                <div class="bb-form-item">
                  <label for="Contrase-a" class="bb-form-label">Contraseña</label>
                  <FormInput
                    class="bb-form"
                    name="password"
                    type="password"
                    v-model="password"
                    :invalid="passwordEmpty"
                    placeholder="Contraseña"
                    :required="true"
                  />
                </div>
              </template>

              <template v-slot:actions>
                <div v-if="resultStatus !== null">
                  <div v-if="resultStatus" class="alert alert--success">
                    Producto añadido a la lista!
                  </div>
                  <div v-else class="alert alert--error">
                    Nombre de usuario o contraseña incorrectos.
                  </div>
                </div>
                <div class="login-box__actions">
                  <input
                    type="submit"
                    :disabled="!valid"
                    value="Acceder"
                    data-wait="Un segundo..."
                    class="button button--primary button--block button--lg"
                  />
                </div>
                <login-social
                  :show="true"
                  :showFacebook="false"
                  :showLabel="true"
                  class="login-box__social"
                  color="white"
                  size="large"
                  origin="accessList"
                ></login-social>
              </template>

              <div class="login-box__recovery">
                <span>¿Olvidaste tu contraseña?</span>
                <span @click="goToRecoverPassword" class="link link--underline">
                  Solicítala de nuevo
                </span>
              </div>
            </Form>

            <div class="login-account login-account--modal">
              <div class="login-account__picture">
                <img
                  src="../../../assets/img/logos/favicon.svg"
                  loading="lazy"
                  alt="Logo"
                />
              </div>
              <div class="login-account__content">
                <span>¿Todavía no tienes lista?</span>
                <span @click="gotToSignup" class="link link--underline"
                  >Créala ahora</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CanBeCanceledByEscKey from "../../../Mixins/CanBeCanceledByEscKey";
import mixPanelUserLogin from "../../mixins/MixPanelUserLogin";
import axios from "axios";
import LoginSocial from "../../../components/ui/login/LoginSocial.vue";

export default {
  mixins: [CanBeCanceledByEscKey, mixPanelUserLogin],
  components: {
    LoginSocial,
  },
  props: {
    goto: {
      type: String,
      default: "myList",
    },
    redirect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      resultStatus: null,
      password: "",
      email: "",
      emailEmpty: false,
      passwordEmpty: false,
      valid: true,
      error: null,
    };
  },
  methods: {
    goToRecoverPassword() {
      this.cancel();
      this.$nextTick(() => this.$router.push({ name: "recoverpwd" }));
    },
    gotToSignup() {
      this.cancel();
      this.$nextTick(() => this.$router.push({ name: "signup" }));
    },
    close() {
      this.cancel();
    },
    async initLogin() {
      let { data: user } = await axios.post("/login", {
        email: this.email,
        password: this.password,
      });
      this.mixPanelUserLogin(user);
      this.$store.commit("setUser", user);
      await this.$store.dispatch("setUserList");
      this.$emit("afterLogin");
      this.cancel();
      if (this.redirect) {
        this.$nextTick(() => this.$router.push(this.goTo));
      }
    },
    async operation() {
      if (this.validations()) {
        try {
          await this.initLogin();
          this.resultStatus = true;
        } catch (e) {
          this.resultStatus = false;
        }
      }
    },
    cancel() {
      this.$emit("cancel");
      this.resultStatus = null;
    },
    validations() {
      this.passwordEmpty = !this.password;
      this.emailEmpty = !this.email;
      return !!this.email && !!this.password;
    },
  },
};
</script>
